.btn-on {
    background-color: #28a745 !important;
    color: white !important;
    transition: all 0.15s ease-in-out;
}

.btn-on:hover {
    background-color: #28a745 !important;
}

.btn-off {
    background-color: #002549;
    color: #008db5;
    transition: all 0.15s ease-in-out;
}

.btn-off:hover {
    background-color: #123960;
}

button {
    cursor: pointer;
}

.btn-link {
    color: inherit !important;
}

.sub-menu-wrapper>.btn:hover {
    background-color: #ccc;
}

.btn-view-more {
    
    border: 1px solid red;
    border-radius: 3px;
    width: 120px;
}

.btn-black {
    
    border: 1px solid white;
    border-radius: 3px;
    width: 140px;
    background-color: black;
    color: white;
}

.btn-white {
    
    border: 1px solid black;
    border-radius: 3px;    
    background-color: white;
    color: black;
}

.btn-red {    
    border: 1px solid #f44336;
    border-radius: 3px;    
    background-color: white;
    color: #f44336;
}

.btn-blackish {
    
    border: 1px solid white;
    border-radius: 3px;
    width: 140px;
    background-color: #252525;
    color: white;
}


.btn-transparent {
    height: 30px;
    border: 1px solid white;
    border-radius: 3px;
    width: 140px;
    background-color: transparent;
    color: white;
}