.navi-bar {
    background-color: rgb(3, 16, 88);
    transition: all 0.5s ease 0s;
}

.corner-2 {
    border-radius: 8px !important;
}

.text-400 {
    height: 120px;
}

.r-180 {
    height: 180px
}

.navi-bar-transparent {
    background: none !important;
    background-color: transparent !important;
}

.w-custom-200-full {
    width: 100%
}

.r-500 {
    height: 500px;
}

.w-fit {
    width: fit-content !important
}

.w3-forth {
    float: left;
    width: 25%;
}

.w-500 {
    max-width: 500px !important;
}

.w-960 {
    max-width: 960px !important;
}

.mh-230-resp {
    min-height: 230px;
}

w .w3-content {
    max-width: 1340px !important;
}

.w3-tres {
    float: left;
    width: 33%;
}

.pl-4-medium {
    padding-left: 2em;
}

.pr-4-medium {
    padding-right: 2em;
}

.mb-100-medium {
    margin-bottom: 100px;
}

@media only screen and (max-width: 768px) {

    .mh-230-resp {
        min-height: auto !important;
    }

    .r-500 {
        height: auto !important;
    }

    .r-180 {
        height: auto !important;
    }

    .mb-100-medium {
        margin-bottom: 0 !important;
    }

    .pl-4-medium {
        padding-left: 0 !important;
    }

    .pr-4-medium {
        padding-right: 0 !important;
    }

    .w3-forth {
        width: 100% !important;
    }

    .w3-tres {
        width: 100% !important;
    }

    .w-custom-200-full {
        width: 200px !important
    }

    /* For everything less or equal 768px */
    .small-header-font-768 {
        font-size: 8pt !important;
    }

    .contact_info {
        display: none;
    }

    .nav-bar.w3-card {
        box-shadow: none !important;
    }

    .resp-m-top {
        margin-top: 2.5em !important;
    }

    .push-down {
        height: 50px;
    }

    .force>.contact_info {
        display: block;
        position: absolute;
        bottom: 10px;
        right: 10px;
        left: 10px;
    }

    .justify-center-768 {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .burger {
        display: flex !important
    }

    .hero3 {
        min-height: 550px !important;
        height: 550px !important;
    }

    .nav-bar {
        background-color: transparent !important;
    }

    .card-triplet {
        display: none;
    }

    .row-into-column,
    .row-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    h1.title {
        font-size: 20pt !important;
    }

    .hero-text {
        font-size: 50px !important;
    }

    #rankings-home .hero-text {
        font-size: 70px !important;
    }

    #rankings-home div.left {
        top: 0;
        bottom: none;
        width: 100% !important;
        height: 350px !important;
    }

    #rankings-home div.right {
        bottom: 0;
        top: none;
        width: 100% !important;
        height: 350px !important;
    }

    .rankings-gender-background>div {
        height: 350px !important;
    }

    .reverse-768 {
        flex-direction: row-reverse !important
    }

    .vertical-gap {
        width: 0 !important
    }

    .sponsor {
        width: 33% !important;
    }

    .sponsor>img {
        max-width: 100px !important;
    }

    .text-right-center {
        text-align: center !important;
    }

    .tournament-card {
        height: 200px !important;
    }

    .ms-auto-768 {
        margin-left: 0 !important;

    }

    .mb-1em-768 {
        margin-bottom: 1em;
    }

    .floating-medal {
        display: none;
    }

    .hide-768 {
        display: block !important;
    }

    .super-small {
        font-size: 8pt !important;
    }

    .small-w-100 {
        width: auto !important;
    }
}

.small-w-100 {
    width: 100%;
}

.hide-768 {
    display: none;
}

.floating-medal {
    position: absolute;
    top: 145px;
    left: 50px;
}

.ms-auto-768 {
    margin-left: auto;
}

.tournament-card {
    height: 180px;
}

.resp-m-top {
    margin-top: 1.5em;
}

.text-right-center {
    text-align: right;
}

#rankings-home div.left {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 50%;
    height: 100%;
}

#rankings-home div.right {
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 50%;
    height: 100%;
}

.rankings-gender-background>div {
    height: 700px;
}

.nav-bar {
    background-color: #252525;
}

.hero3 {
    min-height: 700px;
    height: 700px;
}

.row-into-column,
.row-column {
    -ms-flex-direction: row;
    flex-direction: row;
}

h1.title {
    font-size: 30pt;
}

.reverse-768 {
    flex-direction: row;
}

.vertical-gap {
    width: 50px
}

.sponsor {
    width: 200px
}

.sponsor>img {
    max-width: 200px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    width: 100%;
}

.fc-divider.fc-col-resizer.fc-widget-header {
    display: none;
}