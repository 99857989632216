.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
}

.something {
    animation-name: example;
    animation-duration: 1s;
}

@keyframes example {
    from {
        left: 120px;
    }

    to {
        left: 0
    }
}

.example-enter {
    opacity: 0.01;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.example-leave {
    opacity: 1;
}

.example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}


/* Shake */

.shake {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

@keyframes loadingA {
    0% {
        height: 15px;
    }

    50% {
        height: 35px;
    }

    100% {
        height: 15px;
    }
}

@keyframes loadingB {
    0% {
        width: 15px;
    }

    50% {
        width: 35px;
    }

    100% {
        width: 15px;
    }
}

@keyframes loadingC {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(0, 15px);
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes loadingD {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes loadingE {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes loadingF {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes loadingG {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }

    50% {
        transform: translate(70px, 0) rotate(360deg);
    }

    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}

@keyframes loadingH {
    0% {
        width: 15px;
    }

    50% {
        width: 35px;
        padding: 4px;
    }

    100% {
        width: 15px;
    }
}

@keyframes loadingI {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes bounce {

    0%,
    100% {
        transform: scale(0.0);
    }

    50% {
        transform: scale(1.0);
    }
}

@keyframes loadingJ {

    0%,
    100% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(10em, 0);
        background-color: #2cad3c;
        width: 25px;
    }
}

.bar {
    width: 25px;
    height: 6px;
    background-color: #4b9cdb;
    animation: loadingJ 2s cubic-bezier(.50, .50, .50, 1) infinite;
}


/* Heart beat animation */

@keyframes beat {
    to {
        transform: scale(1.4);
    }
}

.heart {
    animation: beat 1s infinite alternate;
    transform-origin: center;
}


.react-slideshow-container {
    display: flex;
    align-items: center;
    height: 650px;
}

.react-slideshow-container .each-slide>div {
    height: 650px;
    background-size: cover;
    background-position: center center;
}

.react-slideshow-container .nav {
    height: 30px;
    background: rgba(255, 255, 255, 0.6);
    width: 30px;
    z-index: 10;
    text-align: center;
    cursor: pointer;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center
}

.react-slideshow-container .nav span {
    display: block;
    width: 10px;
    height: 10px;
    border-color: #000;
    border-style: solid;
    transform: rotate(45deg)
}

.react-slideshow-container .nav.disabled span {
    border-color: #666
}

.react-slideshow-container .nav:hover {
    background: white;
    color: #666
}

.react-slideshow-container .nav.disabled:hover {
    cursor: not-allowed
}

.react-slideshow-container .nav:first-of-type {
    margin-right: -30px;
    border-right: 0;
    border-top: 0
}

.react-slideshow-container .nav:first-of-type span {
    margin-left: 5px;
    border-right: 0;
    border-top: 0
}

.react-slideshow-container .nav:last-of-type {
    margin-left: -30px
}

.react-slideshow-container .nav:last-of-type span {
    margin-right: 5px;
    border-left: 0;
    border-bottom: 0
}

.react-slideshow-container+div.indicators {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px
}

.react-slideshow-container+div.indicators>.each-slideshow-indicator {
    width: 7px;
    height: 7px;
    margin: 0 5px 10px;
    border-radius: 50%;
    background: #ccc;
    cursor: pointer
}

.react-slideshow-container+div.indicators>.each-slideshow-indicator:hover {
    background: #666
}

.react-slideshow-container+div.indicators>.each-slideshow-indicator.active {
    background: #000
}

.react-slideshow-fade-wrapper {
    width: 100%;
    overflow: hidden
}

.react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap {
    display: flex;
    flex-wrap: wrap
}

.react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap>div {
    position: relative;
    opacity: 0
}

.react-slideshow-wrapper.slide {
    width: 100%;
    overflow: hidden
}

.react-slideshow-wrapper .images-wrap {
    display: flex;
    flex-wrap: wrap
}

.react-slideshow-zoom-wrapper {
    width: 100%;
    overflow: hidden
}

.react-slideshow-zoom-wrapper .zoom-wrapper {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden
}

.react-slideshow-zoom-wrapper .zoom-wrapper>div {
    position: relative;
    display: flex
}

@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
    40% {-webkit-transform: translateY(-30px) }
    60% {-webkit-transform: translateY(-20px);}
} 
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-30px);}
    60% {transform: translateY(-20px);}
} 


.bounce {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-name: bounce;
    animation-name: bounce;
}