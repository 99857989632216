.block {
    display: block !important;
}

.debug {
    border: 1px dashed red !important;
}

.auto {
    margin: auto;
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.padding-5 {
    padding: 5px !important;
}

.no-border {
    border: 0px !important;
}

.idented {
    padding-left: 1em !important;
}

.half-idented,
.idented-half {
    padding-left: 0.5em !important;
}

.bordered {
    border: 1px solid gray !important;
}

.hide {
    display: none !important;
}

.margin-left-double {
    margin-left: 2em;
}

.margin-left {
    margin-left: 1em;
}

.margin-left-half {
    margin-left: 0.5em;
}

.margin-right {
    margin-right: 1em;
}

.margin-right-half {
    margin-right: 0.5em;
}

.margin-top-double {
    margin-top: 2em !important;
}

.margin-top {
    margin-top: 1em !important;
}

.margin-top-half {
    margin-top: 0.5em !important;
}

.margin-bottom-double {
    margin-bottom: 2em !important;
}

.margin-bottom {
    margin-bottom: 1em !important;
}

.margin-bottom-half {
    margin-bottom: 0.5em !important;
}

.padding-left {
    padding-left: 1em;
}

.padding-right {
    padding-right: 1em;
}

.padding-right-half {
    padding-right: 0.5em;
}

.padding-top-double {
    padding-top: 2em !important;
}

.padding-top {
    padding-top: 1em !important;
}

.padding-top-half {
    padding-top: 0.5em !important;
}

.padding-bottom-double {
    padding-bottom: 2em !important;
}

.padding-bottom {
    padding-bottom: 1em !important;
}

.padding-bottom-half {
    padding-bottom: 0.5em !important;
}

.text-left {
    text-align: left !important;
}

.hundred-percent {
    width: 100%;
    height: 100%;
}

.underlined {
    text-decoration: underline !important;
}

.full-height {
    height: 100% !important;
}

.caret-hidden {
    opacity: 0;
}

.transparent {
    opacity: 0.8;
}

.fully-transparent {
    opacity: 0;
}

.no-style-lists,
.no-style-list {
    list-style: none !important;
}


/* Ribbon */

.ribbon-wrapper {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -3px;
    left: -3px;
}

.ribbon-wrapper .ribbon {
    font: bold 15px sans-serif;
    color: #333;
    text-align: center;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 7px 0;
    top: 15px;
    left: -30px;
    width: 120px;
    background-color: #ebb134;
    color: #fff;
}

.inverted {
    transform: rotate(180deg) !important;
}

.fc-event-time {
    display: none;
}

/* React Context Menu */
.react-datepicker-popper {
    z-index: 1000 !important;
}

.fc-daygrid-day-frame {
    min-height: 100px !important;
}

.react-contextmenu {
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 16px;
    color: #373a3c;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
    outline: none;
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
}

.react-contextmenu-item {
    padding: 3px 20px;
    font-weight: 400;
    line-height: 1.5;
    color: #373a3c;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
    cursor: pointer;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    color: #878a8c;
    background-color: transparent;
    border-color: rgba(0, 0, 0, .15);
}

.react-contextmenu-item--divider {
    margin-bottom: 3px;
    padding: 2px 0;
    border-bottom: 1px solid rgba(0, 0, 0, .15);
    cursor: inherit;
}

.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, .15);
}

.react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-middle {
    vertical-align: middle;
}


/*[ Width & Height ]
-----------------------------------------------------------
*/

.sizefull {
    width: 100%;
    height: 100%;
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.max-w-full {
    max-width: 100%;
}

.max-h-full {
    max-height: 100%;
}

.min-w-full {
    min-width: 100%;
}

.min-h-full {
    min-height: 100%;
}

.pointer {
    cursor: pointer;
}

.zoom {
    transition: all 0.5s ease 0s;
}

.zoom:hover {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
    transform: scale(1.4);
}

.pixel-hack {
    width: 1px;
    height: 1px;
    position: absolute;
}

.text-clip {
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.line1 {
    line-height: 1;
}

.pb-8,
.py-4 {
    padding-bottom: 3rem !important;
}

.pt-8,
.py-4 {
    padding-top: 3rem !important;
}

#signature-pad {
    width: 100% !important;
}

.m-signature-pad {
    margin: 0;
    width: 100% !important;
    background-color: #FFFFFF;
}

.m-signature-pad--body canvas {
    /*background: url('/images/sign.png') no-repeat center center;*/
    width: 100% !important;
    height: 250px;
}

.modal-title {
    width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}