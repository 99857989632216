.green,
.success,
.Active,
.Completed {
    color: #2cad3c !important;
}

.blue {
    color: #1771FF
}

.white {
    color: white !important;
}

.whiteish {
    color: #B4BFCB !important;
}

.white-ish {
    color: #9a9a9a !important;
}

.left-menu-link {
    color: #9A9A9A;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.left-menu-link:hover {
    color: white;
}

.blue-dark,
.Shopping {
    color: #002549 !important;
}

.navy {
    color: #0433C4 !important;
}

.gray {
    color: #343F4B !important;
}

.bg-gray-3 {
    background-color: #15191E !important;
}

.lile {
    color: #8CA0DE !important
}

.bg-lile {
    background-color: #8CA0DE !important
}

.black {
    color: black !important;
}

.blue-link {
    color: #03aeef !important
}

.blue,
.Pending {
    color: #1771FF !important;
}

.bg-blue {
    background-color: #1771FF;
}

.bg-gray-light {
    background-color: #343F4B;
}

.btn-blue {
    background-color: #1771ff !important;
    border-radius: 22px;
}

.btn-green {
    background-color: #2cad3c !important;
    border-radius: 22px;
}

.btn-link.bordered {
    border-radius: 22px;
    border: 2px solid #343f4b !important;
}

.btn-gray-2 {
    background-color: #0e1115;
    border-radius: 22px;
}

.btn-black {
    background-color: #0A0B0E;
    border-color: #0A0B0E;
    border-radius: 22px;
}

.btn-gray-light {
    background-color: #343F4B !important;
    border-color: #343F4B;
    border-radius: 22px;
}

.gray-light {
    color: gray;
}


.yellow,
.Waitlisted,
.Waiting {
    color: #FFC00E !important;
}

.orange {
    color: #F3A833 !important;
}

.border-blue {
    border-color: #1771FF !important;
}

.red,
.rejected,
.Inactive,
.Denied,
.Late {
    color: #dc3545 !important;
}

.transparent {
    color: transparent !important;
}

.input-danger {
    border: 2px solid #dc3545 !important;
}

.teal {
    color: teal;
}

.mint {
    color: #E1F0E5 !important
}

.bg-mint {
    background-color: #E1F0E5 !important
}

.cream {
    color: #FFF5DF !important
}

.bg-cream {
    background-color: #FFF5DF !important
}

.lavander {
    color: #E7EBF7 !important
}

.bg-lavander {
    background-color: #E7EBF7 !important
}

.purple,
.Tryout {
    color: #77498B !important;
}

.bg-purple {
    background-color: #77498B !important;
}

.bg-green {
    background-color: #289F45 !important;
}

.bg-green-dark {
    background-color: #0D561E !important;
}

.bg-yellow {
    background-color: #FFC00E !important
}

.bg-dark-blue {
    background-color: #143465 !important;
}

.bg-navy {
    background-color: #0433C4 !important;
}

.bg-gray-2 {
    background-color: #0e1115 !important;
}

th.bg-gray-2,
th.bg-gray-3,
th.bg-black,
th.bg-transparent {
    border-color: #343f4b;
}

td.bg-gray-2,
td.bg-gray-3 {
    border-color: #15191E;
}

.bg-blue-light {
    background-color: #E8EBF6 !important;
}

.bg-darker-blue,
.bg-blue-dark {
    background-color: #001A36 !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-black {
    background-color: #0a0b0e !important
}

.bg-blackish {
    background-color: #252525 !important
}

.bg-gray {
    background-color: #CCC !important
}

.bg-red {
    background-color: #f44336 !important;
}

.bg-gray-darkish {
    background-color: #8F959C !important
}

.circle {
    border-radius: 50%;
}

.bg-whiteish {
    background-color: #EAEAEA !important;
}

.bg-gray-dark {
    background-color: #15191E !important
}

.bg-transparent {
    background-color: transparent !important
}

.bg-Competitive {
    background-color: #6a0e33;
}

.bg-Camp,
.bg-orange {
    background-color: #fd930c;
}

.bg-Recreational {
    background-color: #259bd9;
}

.bg-Tournament {
    background-color: #4fb03c;
}

.color-Competitive {
    color: #6a0e33;
}

.color-Camp {
    color: #fd930c;
}

.color-Recreational {
    color: #259bd9;
}

.color-Tournament {
    color: #4fb03c;
}

.jeff_blue {
    color: #013469 !important;
}

.jeff_blue_back {
    background-color: #013469 !important;
}

.inner-shadow {
    -moz-box-shadow: inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow: inset 0 0 10px #000000;
}

input.dark,
button.dark,
textarea.dark {
    background-color: #AAA;
    padding: 8px;
    display: block;
    border: none;
    width: 100%;
}

.premium-gradient {
    background: rgb(100, 169, 212);
    background: linear-gradient(90deg, rgba(100, 169, 212, 1) 0%, rgba(172, 215, 100, 1) 100%);
}

table.fc-scrollgrid,
.fc-scrollgrid th,
.fc-scrollgrid td {
    border: 1px solid #15191E !important;
}

.fc-event-main {
    color: inherit !important
}